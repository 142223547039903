
import firebase from 'firebase/app';
import { Observable ,  of } from 'rxjs';
import { take ,  takeUntil ,  switchMap, map, tap, catchError } from 'rxjs/operators';
import { User } from '../interfaces/user.model';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AppSetting } from '../interfaces/appSetting';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({ providedIn: 'root' })
export class AuthService {
 
  public user: Observable<User>;
  public appSetting: Observable<AppSetting>;
  public appS : AppSetting ;
  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    private router: Router,
    private messageService: ToastrService
  ) {
    this.user = this.afAuth.user
      .pipe(
        switchMap((auth) => {
          if (auth) {
            auth.sendEmailVerification
            return this.db.object('users/' + auth.uid).valueChanges()
              .pipe(
                map((user: User) => {
                  return {
                    ...user,
                    uid: auth.uid
                  };
                })
              );
          } else {
            return of(null);
          }
        })
      );

    
        
  }

  


 
  
 

async register(email: string, password: string, firstname: string, lastname: string, phone: string) {
  var result = await this.afAuth.createUserWithEmailAndPassword(email, password)
  
  
}

  public emailSignUp(email: string, password: string, firstname: string, lastname: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then(
        (user) => {

          console.log(user)
         return user.user.uid;
        },
        (error) => {
          throw error;
        }
      );
  }

   
  emailLogin(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password).then(
      (user) => {
        //this.updateNewUser(user.user);
      },
      (error) => {
        throw error;
      }
    );
  }

  public signOut() {
    this.afAuth.signOut();
    this.messageService.info('You have been logged out.');
  }

  public updateProfile(userData: User) {
    this.updateExistingUser(userData);
    this.messageService.info('User profile has been updated!');
  }
/*
  public updatePassword(password: string) {
    return this.afAuth.currentUser
      .updatePassword(password)
      .then(() => {
        this.messageService.info('Password has been updated!');
      })
      .catch(function(error) {
        throw error;
      });
  }

  public updateEmail(email: string) {
    return this.afAuth.currentUser
      .updateEmail(email)
      .then(() => {
        this.updateExistingUser({ email: email });
        this.messageService.info('User email have been updated!');
      })
      .catch(function(error) {
        throw error;
      });
  }
  */

  updateNewUser(uid: string,firstname: string,lastname: string,email: string,password: string,role: boolean) {
    this.db.object('/users/' + uid).update({
      firstName: firstname,
      lastName: lastname,
      email: email,
      password:password,
      roles: {
        admin : role
      }
      
    });
  }

  

  private updateExistingUser(userData) {
    const currentUser = firebase.auth().currentUser;
    const ref = this.db.object('users/' + currentUser.uid);
    ref
      .valueChanges()
      .pipe(
        take(1)
      )
      .subscribe((user) => {
        ref.update(userData);
      });
  }
}
