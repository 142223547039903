import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MessageService } from './message.service';
declare let Email: any;
@Injectable()
export class SendMailService {

  constructor(private _http: HttpClient , private toastr:MessageService,private router:Router) {}

  sendMessage(body) {
      const url = 'https://api.smtp2go.com/v3/email/send'
      const headers = {'Content-Type': "application/json"};
      this._http.post<any>(url, body, { headers }).subscribe(response => {
        console.log(response.data.succeeded)
        if(response.data.succeeded==1){
            this.toastr.add('Message sent successfully')
           // this.router.navigate(['/home']);
        }else{
            this.toastr.add('Message not sent ')

            this.toastr.addError('Error')
        }
    });
}
}