import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {TranslateModule} from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
// Bootstrap DataTable
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';

import { RichTextEditorAllModule, RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { TabModule, ToolbarModule } from '@syncfusion/ej2-angular-navigations';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { MessageService } from './services/message.service';
import { DatePipe } from '@angular/common';
import { SendMailService } from './services/send-mail.service';

const config = {
  apiKey: "AIzaSyBC_oFI5beMkq3rOF1W2chTfsro7NdmCg8",
  authDomain: "egctelecome.firebaseapp.com",
  databaseURL: "https://egctelecome-default-rtdb.firebaseio.com",
  projectId: "egctelecome",
  storageBucket: "egctelecome.appspot.com",
  messagingSenderId: "941564714467",
  appId: "1:941564714467:web:12392fa46dc825bdfb54d6",
  measurementId: "G-CKPTD5Q0YQ"
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    ToolbarModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DataTablesModule,
    RichTextEditorModule,
    CodemirrorModule,
    TabModule,
    HttpClientModule,
    RichTextEditorAllModule,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot()
  ],
  providers: [MessageService,DatePipe,SendMailService],
  bootstrap: [AppComponent]
})
export class AppModule { }
